/* global ArborPlayer:readonly */

const getUrlParameter = (sParameter) => {
  const sPageURL = decodeURIComponent(window.location.search.slice(1));
  const sURLVariables = sPageURL.split('&');
  let sParameterName;
  let i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === sParameter) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
};

const createSocialFunctions = () => {
  const pageUrl = document.URL;
  const pageUrle = encodeURIComponent(pageUrl);

  const facebookLink = 'http://www.facebook.com/share.php?u=' + pageUrle;
  const twitterTitle =
    'I would like to share a new link from the European Parliament :';
  const twitterTitlee = encodeURIComponent(twitterTitle);
  const twitterLink =
    'http://twitter.com/home?status=' + twitterTitlee + '%20' + pageUrle;
  const linkedinTitle = 'A new event of the European Parliament is available';
  const linkedinTitlee = encodeURIComponent(linkedinTitle);
  const linkedinLink =
    'https://www.linkedin.com/shareArticle?mini=true&title=' +
    linkedinTitlee +
    '&url=' +
    pageUrle;
  const mailTitlee = linkedinTitlee;
  const mailLink =
    'mailto:your.destination@email.com?subject=' +
    mailTitlee +
    '&body=' +
    pageUrle;

  document.querySelector('#facebookLink').href = facebookLink;
  document.querySelector('#twitterLink').href = twitterLink;
  document.querySelector('#linkedinLink').href = linkedinLink;
  document.querySelector('#mailLink').href = mailLink;
};

const addStartAndEndTimeToTitle = (title) => {
  const sharedStartTime = getUrlParameter('start');
  const sharedEndTime = getUrlParameter('end');
  if (sharedStartTime) {
    const sharedStartTimeArray = sharedStartTime.split('T');
    const sharedEndTimeArray = sharedEndTime.split('T');
    title =
      title +
      ' ( ' +
      sharedStartTimeArray[1] +
      ' - ' +
      sharedEndTimeArray[1] +
      ')';
  }

  document.title = title;
};

const createArborPlayer = () => {
  // var configMeetingId = getUrlParameter("event");
  const configRefId = getUrlParameter('event'); // changed parameter name from refid to event
  const configEnv = getUrlParameter('env') || 'production';
  const configUrl =
    configEnv === 'production'
      ? 'https://acs.europarl.connectedviews.eu'
      : 'https://acs.europarl.acc.connectedviews.eu';

  const configLanguage = getUrlParameter('language') || 'en';

  const configAutoplay = false;
  const configLogoEnabled = true;

  const configMulticast = getUrlParameter('multicast') !== 'false';

  // var configRampEnabled = false;  // deprecated
  // if (getUrlParameter("rampActive")) {configRampEnabled = true}; // deprecated

  const configSeekTo = getUrlParameter('seekTo');

  const sharedStartTime = getUrlParameter('start');
  const sharedEndTime = getUrlParameter('end');

  const ready = (fn) => {
    switch (document.readyState) {
      case 'loading':
      case 'interactive': {
        window.addEventListener('DOMContentLoaded', fn);
        break;
      }

      case 'complete': {
        fn();
        break;
      }

      default: {
        break;
      }
    }
  };

  ready(() => {
    console.log('language:' + configLanguage);
    createSocialFunctions();
    addStartAndEndTimeToTitle(document.title);

    const element = document.querySelector('.ep_video'); // Fetch the Arbor Media ACS Videplayer container div

    return new ArborPlayer( // Instantiates the videoplayer
      element, // The container element to inject the player in.
      configUrl, // The CVC5 domain to get the meeting from.

      {
        // meetingId: configMeetingId,                                // meetingId:  The ID of the meeting in the CVC5 environment.
        refid: configRefId, // refid : Testing external refid
        autoplay: configAutoplay, // autoplay:   Default false, when true the player will automaticly start with playback
        muted: true, // muted:      Default false, when true the player's audio will be muted.
        logo: configLogoEnabled, // logo:       Default true, when false the player will hide the logo
        fullscreen: true, // fullscreen: Default true, when false the player will hide the full screen button
        disclaimer: true, // disclaimer: Default true, when false the player will hide the disclaimer button
        start: sharedStartTime, // start:      Set an offset for the start of the meeting.
        end: sharedEndTime, // end:        Set an offset for the end of the meeting.
        multicast: configMulticast, // multicast:  Default true, when false the player will not try to use multicast.
        lang: configLanguage, // lang:       Default 'en', use iso2 language code to ajust the langauge of the ui.
        audio: configLanguage, // audio:      Default 'qa', use iso2 language code to ajust the audio language, when not available it will fallback to original audio
        seekto: configSeekTo, // seekTo:     Start at a specific point in the time in the video. Needs to be in yyMMDDhhmmss format
        analytics: true, // analytics:  Default false, when true embed player will collect user stats otherwise it will not collect usage information.
      },
      ''
    ); // (optional)  The max width of the videoplayer
  });
};
